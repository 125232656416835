
<template>
  <div class="cooperation">
    <div class="partners-detail">
      <ul>
        <li v-for="item in cooperationList" :title="item.title">
          <div class="line t"></div>
          <div class="line b"></div>
          <div class="line l"></div>
          <div class="line r"></div>
          <el-image
            :src="global.imgUrl + item.thumb"
            @click="goLinkUrl(item)"
          ></el-image>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { getdetails, getCooperationlist } from "@/api";
export default {
  name: "cooperation",
  data() {
    return {
      cooperationList: [],
    };
  },
  methods: {
    async start() {
      // let res = await axios.get(
      //   `https://mcnki.obs.cn-east-2.myhuaweicloud.com/js/193-0-0.js`
      // );
      // console.log(res)
      // this.cooperationList = res.data
      await getCooperationlist().then((res) => {
        console.log(res);
        this.cooperationList = res.data.reverse();
      });
    },
    goLinkUrl(item) {
      window.open(item.LinkUrl, "_blank");
    },
  },
  mounted() {
    this.start();
  },
};
</script>

<style lang="less" scoped>
.partners-detail {
  background: #fff;
  padding: 20px;
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      .line {
        background: #eaeaea;
        position: absolute;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
      }
      .t {
        top: 0;
        width: 90%;
        height: 1px;
        left: 5%;
      }
      .b {
        bottom: 0;
        width: 90%;
        height: 1px;
        left: 5%;
      }
      .l {
        left: 0;
        width: 1px;
        height: 90%;
        top: 5%;
      }
      .r {
        right: 0;
        width: 1px;
        height: 90%;
        top: 5%;
      }
      width: 20%;
      position: relative;
      min-height: 120px;
      margin-left: -1px;
      margin-bottom: -1px;
      transition: all 0.3s;
      //  overflow: hidden;
      .el-image {
        margin: 0 auto;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        max-width: 80%;
        max-height: 80%;
        z-index: 100;
        image-rendering: -webkit-optimize-contrast;
      }
      
    }
    li:hover {
        cursor: pointer;
        box-shadow: 0 10px 20px 0 rgba(0, 23, 43, 0.1);
      }
  }
}
.main-text {
  margin: 20px 0;
  line-height: 34px;
  /deep/img {
    margin: 0 auto !important;
  }
  /deep/p {
    color: #464646 !important;
    font-size: 16px !important;
    line-height: 1.75rem !important;
    font-family: "PINGFANG-REGULAR" !important;
    span {
      color: #464646 !important;
      font-size: 16px !important;
      line-height: 1.75rem !important;
      font-family: "PINGFANG-REGULAR" !important;
    }
  }
}
</style>